@media (min-width: 600px) {
  tbody {
    display: block;
    height: 280px;
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    width: calc(100% - 1em);
  }
  table {
    width: 100%;
  }
}

/* .my-class {
  height: 250px !important;
} */
